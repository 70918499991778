import store from "@/store/index.js";
import ErrorService from "@/services/ErrorService";
import RequestService from "@/services/RequestService";

export default class PermissionService extends RequestService {
  constructor(baseEndpoint) {
    super(baseEndpoint);
  }

  me = async (endpoint) => {
    try {
      const { data } = await this.get(endpoint);

      await store.dispatch("permissions", data);
    } catch (exception) {
      throw ErrorService(exception);
    }
  };
}
