<template>
  <v-card width="310" rounded="lg">
    <v-toolbar flat>
      <v-toolbar-title class="toolbar-title"> Bem-Vindo </v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form>
        <v-text-field
          type="text"
          label="E-mail*"
          autocomplete="off"
          prepend-icon="fas fa-envelope"
          :autofocus="true"
          :disabled="loadButtonSubmit"
          v-model="data.email"
          :error-messages="errors.email"
        ></v-text-field>

        <v-text-field
          type="password"
          label="Senha*"
          prepend-icon="fas fa-lock"
          :disabled="loadButtonSubmit"
          v-model="data.password"
          :error-messages="errors.password"
        ></v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="ma-4 white--text no-text-transform"
        :loading="loadButtonSubmit"
        @click="handleSubmitData()"
      >
        Entrar
        <v-icon right dark>fas fa-sign-in-alt</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import AuthService from "@/services/Auth/AuthService";
import PermissionService from "@/services/Auth/PermissionService";

export default {
  name: "Login",

  data: () => ({
    data: {},
    errors: {},
    loadButtonSubmit: false,
    AuthService: new AuthService(""),
    PermissionService: new PermissionService("/permission"),
  }),

  methods: {
    async handleSubmitData() {
      try {
        this.errors = {};

        this.loadButtonSubmit = true;

        await this.AuthService.login("auth/login", this.data);

        await this.AuthService.profile("auth/permissions");

        await this.AuthService.me("auth/me");

        window.auth
          .signInWithEmailAndPassword(this.data.email, 'wsjus-password-auth')
          .then(() => {
            this.$router.push({ path: "/app/painel" });
          })
          .catch((err) => {
            console.log(err);
            ToastService("Não foi possivel autenticar");
          });
      } catch (exception) {
        console.log(exception)
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loadButtonSubmit = false;
      }
    },
  },
};
</script>

<style scoped>
.toolbar-title {
  margin-top: 25px;
  color: #0b6775;
  font-weight: bold;
  text-align: center;
  width: 100% !important;
}
</style>
